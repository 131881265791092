import React from 'react'
import { Link } from 'gatsby'

class Emergenza extends React.Component {

  render() { 
    return (
      <div className="webcontent">
        <h2 className="webcontent__title">Emergenza H24</h2>
        <div className="webcontent__abstract">
          <p>
            Se il tuo cane o gatto non sta bene e hai bisogno di un consulto, se hai un'emergenza magari notturna, i servizi di pronto soccorso  fanno al caso tuo.
          </p>
        </div>
        <Link to="/emergenza/" title="Hai un'emergenza?" className="btn-link btn-link--red btn-link--has-icon" 
        style={{backgroundImage: 'url(/images/contents/icona_emergenza.png)'}}>Hai un'emergenza?</Link>
      </div>
    )
  }

}

export default Emergenza;