import React from 'react'
import Helmet from 'react-helmet'
import Placeholder from '../components/placeholder'
import Breadcrumbs from '../components/breadcrumbs'
import Emergenza from '../components/emergenza'
import DoveSiamo from '../components/dove-siamo'
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"


const breadcrumb = [{"title":"Reparti","active":true}];

class Reparti extends React.Component {

  render() { 
    const data = this.props.data.serviziJson;

    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Servizi"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <section id="servizi-clinici" className="section"  aria-labelledby="region1">
                <h1 id="region1" className="section__title">Reparti</h1>
                <div className="reparti-grid reparti-grid--small">
                  {data.reparti.map((item,index) => 
                   
                      <Link to={item.link} title={item.title} className="reparti-grid__item">
                        <img src={item.img} alt={item.title} className="reparti-grid__img" />
                        <div className="reparti-grid__title">{item.title}</div>
                      </Link>  
                   
                  )}
                </div>
              </section>
            
             {/*  <section id="servizi-diagnositica" className="section"  aria-labelledby="region2">
                <h1 id="region2" className="section__title">Servizi di diagnostica</h1>
                <div className="row flex-wrap">
                {data.diagnostici.horizontal.map((item,index) => {
                    return <div key={index} className="col-6">
                      <Placeholder data={item}/>
                    </div>
                  })}
                </div>
              </section> */}
            </div>
            <div className="col-md-4">
             
              <Emergenza/>
              <DoveSiamo/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default Reparti;

export const query = graphql`{
    serviziJson {
      diagnostici{
        horizontal {
          title
          subtitle
          img
          link
        }
      }
      reparti {
        title
        img
        link
      }
    }
  }
`
